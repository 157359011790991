.CardPort {
    max-width: 100%;
    width: 1040px;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: -300px;
}
.PortHeader h1 {
    font-family: courier-prime, monospace;
    font-size: 120px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 0;
 }
 .PortHeader h2 {
     color: white;
    font-family: courier-prime, monospace;
    font-size: 50px;
    font-weight: 800;
    text-align: center;
    margin: -20px 0 0 0;
 }
.PortInfo { margin-top: 120px; }
.PortInfo .Feature {
    width: 50%;
    height: 400px;
    position: relative;
    display: inline-flex;
    float: left;
    border-radius: 20px;
}
.MakeABag { background-image: url(./images/makeabag.jpg); background-size: 100% auto; background-repeat: no-repeat; }
.CFConfig { background-image: url(./images/cfconfig.jpg); background-size: 100% auto; background-repeat: no-repeat; }
.CFStore { background-image: url(./images/cfstore.jpg); background-size: 100% auto; background-repeat: no-repeat; }
.Creations { background-image: url(./images/creations.jpg); background-size: 100% auto; background-repeat: no-repeat; }
.PortInfo .Details { width: 50%; float: right; }
.PortTitle { margin: 10px 0 0 60px; }
.PortTitle h1 {
    color: white;
    font-weight: 800;
    font-size: 50px;
    line-height: 50px;
    margin: 0;
}
.PortDes { margin: 40px 0 0 60px; color: white; }
.PortLink ul { margin: 10px 0 0 60px; padding: 10px 0; list-style-type: none; }
.PortLink .mPrototype, .PortLink .tPrototype, .CompanySite {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}
.PortLink .mPrototype, .PortLink .tPrototype {
    width: 30%;
    color: #f98ea1;
    text-align: center;
    margin: 8px 8px 0 0;
    padding: 8px 30px;
    display: inline-block;
    border-radius: 5px;
    background-color: #3a4164;
    transition: background-color 0.5s ease;
}
.PortLink .mPrototype:hover, .PortLink .tPrototype:hover { background-color: #4c577f; }
.PortLink .CompanySite {
    color: #FFF6CE;
    padding-top: 20px;
    text-decoration: underline;
    transition: color 0.5s ease;
}
.PortLink .CompanySite:hover { color: white; }

@media only screen and (max-width: 850px) {
    .PortHeader h1 { font-size: 80px; }
    .PortHeader h2 { font-size: 42px; }
    .PortInfo { margin-top: 80px; }
    .PortInfo .Feature { border-radius: 0; height: 300px; }
    .PortInfo .Feature, .PortInfo .Details { width: 100%; float: unset;}
    .PortInfo .Details { padding-top: 40px; }
    .PortTitle { text-align: center; margin: 0; }
    .PortDes { margin: 30px 40px; }
    .PortLink ul { margin: 0 40px; padding: 0; }
    .PortLink .mPrototype, .PortLink .tPrototype { width: auto; margin: 10px 0; padding: 10px auto; display: block; }
    .PortLink .CompanySite { text-align: center; }
}