.CardProfile, .CardDes, .CardEdu { animation: fadeInDown .8s ease-in both; }
.CardDes { animation-delay: .8s; }
.CardEdu { animation-delay: 1.6s; }
.CardExp, .CardPort, .footer { animation: fadeIn .8s ease-in both; }
.CardExp { animation-delay: 2.4s; }
.CardPort { animation-delay: 3.2s; }

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
