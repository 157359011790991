.CardDes {
  max-width: 100%;
  width: 1040px;
  height: 570px;
  margin: 20px auto;
  top: -300px;
  left: -30px;
  border-radius: 40px;
  background-color: #3a4164;
  position: relative;
  z-index: 4;
}
.DesInfo {
  position: absolute;
  bottom: 0;
}
.ProfileDes {
  color: white;
  display: inline-block;
  padding: 20px 60px;
  width: 90%;
}
.hl {
  width: 100%;
  border-top: 2px dashed #f98ea1;
}
.Skills {
  display: table;
  margin: 0 auto;
}
.Skills h2 {
  font-family: chalkduster;
  font-weight: normal;
  font-size: 32px;
  color: #f98ea1;
  position: relative;
  display: inline-block;
  margin-right: 30px;
  top: -10px;
}
.SkillIcon {
  position: relative;
  display: inline-block;
  margin: 30px 0;
}
.SketchIcon, .XDIcon, .PSIcon, .AIIcon, .InvisIcon, .CodeIcon {
  height: auto;
  margin: 0 5px;
  position: relative;
  display: inline-block;
}
.SketchIcon { width: 45px; }
.XDIcon, .PSIcon, .AIIcon, .InvisIcon, .CodeIcon { width: 41px; }

@media only screen and (max-width: 850px) {
  .CardDes { width: auto; left: 0; margin: 5px; height: 800px; }
  .ProfileDes { width: auto; padding: 30px; }
  .Skills { width: 100%; }
  .Skills h2 {
    text-align: center;
    width: 100%;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .SkillIcon { width: 100%; margin: 0 auto 30px auto; display: table; text-align: center; }
  .SketchIcon, .XDIcon, .PSIcon, .AIIcon, .InvisIcon, .CodeIcon { width: 38px; }
}