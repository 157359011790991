.footer {
    height: 40px;
    background-color: rgba(255, 255, 255, .9);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
}
.footer p {
    font-size: 12px;
    color: #3a4164;
    margin-left: 20px;
    position: relative;
    display: inline-block;
}
.footer .Social {
    float: right;
    top: 8px;
    position: relative;
    margin-right: 20px;
}
.footer .Social .Icon {
    height: auto;
    width: 20px;
    margin: 0 10px;
    position: relative;
    display: inline-block;
}

@media only screen and (max-width: 850px) {
    .footer p {display: none; }
    .footer .Social { 
        width: auto; 
        display: block;
        margin: -1px auto 0 auto;
        text-align: center;
        float: unset;
    }
}