@font-face {
  font-family: 'Chalkduster';
  src: url('fonts/chalkduster.ttf') format('truetype'), url('fonts/chalkduster.woff') format('woff');
}  

body {
  margin: 0;
  padding: 0;
  font-family: nunito, sans-serif, Chalkduster;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#f3acba, #3a4164);
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, button, input {
  color: inherit;
  text-decoration: inherit;
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

* { -webkit-overflow-scrolling: touch; }