.CardProfile {
  max-width: 100%;
  width: 1040px;
  height: auto;
  margin: 40px auto 0 auto;
  border-radius: 40px;
  background-color: white;
  position: relative;
  z-index: 5;
}
.hl-top {
  background-image: url(./images/pinkLine.png);
  background-repeat: space;
  background-size: 60px auto;
  max-width: 100%;
  height: 20px;
  margin: 0 40px;
}
.hl-bottom {
  background-image: url(./images/pinkLine.png);
  background-repeat: space;
  background-size: 60px auto;
  position: absolute;
  min-width: 93%;
  height: 20px;
  margin: 0 40px;
  bottom: 0;
  left: 0;
}
.PersonalInfo, .Headline { position: relative; }
.PersonalInfo { width: 37%; float: right; margin: 10px 4% 0 2%; }
.Badge { width: 100%; margin-top: 10px; }
.PersonalInfo .Info h1 {
  font-family: courier-prime, monospace;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2px;
  color: #f98ea1;
  font-size: 38px;
  margin: 5px 0; 
}
.ContactInfo { list-style: none; padding: 0; margin-top: 0; margin-bottom: 45px; }
.ContactInfo li {
  font-weight: 500;
  font-style: normal;
  padding: 0 30px 10px 15px;
  display: inline-flex;
  line-height: 22px;
  width: 100%;
}
.ContactInfo li::before { 
  content: '';
  background-repeat: no-repeat;
  width: 20px;
  margin-right: 20px;
  margin-top: 3.5px;
}
.Home::before { background-image: url(./images/home.png); background-size: 20px auto; }
.Email::before { background-image: url(./images/mail.png); background-size: 20px auto; }
.Tel::before { background-image: url(./images/phone.png); background-size: auto 18px; }
.Headline { width: 50%; float: left; margin: 40px 3% 0 4%; }
.Quote { position: absolute; width: 100px; height: auto; }
.Headline h2 {
  font-family: chalkduster;
  font-weight: normal;
  font-size: 26px;
  line-height: 44px;
  position: relative;
  transform: rotate(-5deg);
  padding: 0 0 0 20px;
  z-index: 1000; }
.vl {
  position: absolute;
  border-left: 1.5px dashed #3a4164;
  height: 340px;
  margin: 25px 0;
  left: 55%;
}

@media only screen and (max-width: 1023px) {
  .PersonalInfo .Info h1 { font-size: 32px; }
  .Headline { margin-top: 20px; }
}

@media only screen and (max-width: 850px) {
  .CardProfile { width: auto; margin: 10px; }
  .PersonalInfo, .Headline { 
    margin: 40px auto;
    width: 89%;
    float: unset;
  }
  .PersonalInfo { padding-bottom: 10px; }
  .PersonalInfo .Info h1 { font-size: 32px; }
  .ContactInfo li { padding: 0 0 10px 0; }
  .ContactInfo li::before { width: 23px; }
  .Quote { width: 75px; }
  .Headline h2 { font-size: 18px; line-height: 36px; }
  .vl { 
    left: unset; 
    transform: rotate(90deg); 
    position: relative;
    margin-top: 60px;
    margin-bottom: -380px;
  }
  .hl-bottom { min-width: 80%; }
}