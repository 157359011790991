.CardEdu {
    width: 400px;
    height: 110px;
    position: absolute;
    left: 53%;
    top: 750px;
    background-image: url(./images/edu-card.png);
    z-index: 3;
}
.EduIcon {
    width: 45px;
    height: auto;
    margin: 25px 0 20px 35px;
    float: left;
}
.EduInfo {
    margin: 20px 35px 0 0;
    float: right;
}
.EduInfo .Degree { font-weight: 800; }

@media only screen and (max-width: 850px) {
    .CardEdu {
        position: relative;
        width: auto;
        margin: 0 38px;
        left: 0;
        top: -306px;
    }
    .EduIcon { width: 20px; margin: 18px 0 20px 10px; }
    .EduInfo { margin: 15px 0 0 10px; float: unset; }
    .EduIcon, .EduInfo { display: inline-block; position: relative; }
}