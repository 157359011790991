.CardExp {
    max-width: 100%;
    width: 1040px;
    height: auto;
    margin: 30px auto;
    border-radius: 40px;
    background-color: white;
    position: relative;
    top: -300px;
    z-index: 1;
}
.ExpInfo { padding: 20px 60px 5px 60px; }
.ExpInfo h3 {
    color: #f98ea1;
    font-weight: 800;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 30px;
    display: inline-block;
}
.Exp { margin-bottom: 30px; }
.ExpInfo .Info { margin-top: 20px; }
.Year { width: 18%; float: left; color: #9097be; }
.Dot { width: 20px; float: left; margin-top: 5px; }
.ExpInfo .Info .Details { width: 75%; float: right; }
.Position, .Company { margin-left: 15px; }
.Year, .Position { font-weight: 800; font-size: 20px; }
.Company, .Location { color: #9097be; display: inline-block; }
.Company::after {
    content: '|';
    color: #e1e2eb;
    padding: 5px;
}
.ExpDes {
    background-color: #f9f5f6;
    border-radius: 20px;
    margin: 15px 0;
    padding: 5px 30px;
}
.clear { clear:both; }
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f98ea1;
    position: relative;
    display: inline-block;
    top: -5px;
    margin-left: 20px;
}
.arrow-right {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #f98ea1;
    position: relative;
    display: inline-block;
    margin-left: 20px;
}

@media only screen and (max-width: 850px) {
    .CardExp { width: auto; margin: 10px; }
    .ExpInfo { padding: 10px 15px; }
    .ExpInfo button { width: 100%; }
    .ExpInfo h3 { float: left; margin-left: 10px; margin-bottom: 10px; }
    .ExpInfo .arrow-down { float: right; margin-right: 15px;; margin-top: 30px; }
    .ExpInfo .arrow-right { float: right; margin-right: 15px;; margin-top: 20px; }
    .Year, .Dot { position: absolute; }
    .Year { width: 50%; padding-left: 40px; }
    .Dot { padding-left: 10px; }
    .ExpInfo .Info .Details { width: 100%; padding-top: 40px; }
    .Position, .Company { margin-left: 10px; }
    .Company, .Location { font-size: 13px; }
}